<template>
  <div class="tab-pane fade" id="create-bot" role="tabpanel" aria-labelledby="create-bot-tab">
    <div>
      <div class="mb-4">
        <div>
          <h5 class="mb-1">{{ $t("t-securely-connect-your-account") }}</h5>
          <p class="text-muted">
            {{ $t("t-create-api-key-and-input") }}
          </p>
          <p class="text-muted">
            <a :href='$t("t-doc-manuals-doc1_path")' target="_blank">
              <i class="mdi mdi-file-document"></i>&nbsp;
              <span>{{ $t("t-doc-manuals-doc1") }}</span>
            </a>
            <br>
            <a href='/documents/bitget/integration-with-us' target="_blank">
              <i class="mdi mdi-file-document"></i>&nbsp;
              <span>{{ $t("t-doc-manuals-doc2") }}</span>
            </a>
            <br>
            <a v-if="currentLocale() === 'ru'" target="_blank" href='https://www.binance.com/ru/support'>
              <i class="mdi mdi-file-document"></i>&nbsp;
              <span>Центр поддержки Binance</span>
            </a>
            <br>
            <a v-if="currentLocale() === 'en'" target="_blank" href='https://www.binance.com/en/support'>
              <i class="mdi mdi-file-document"></i>&nbsp;
              <span>Binance help center</span>
            </a>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 mt-3">
          <spot-strategy1 v-on:select-strategy="this.predefine_strategy = 1"></spot-strategy1>
        </div>
        <div class="col-xs-12 mt-3">
          <spot-strategy2  v-on:select-strategy="this.predefine_strategy = 2"></spot-strategy2>
        </div>
      </div>
      <div class="text-start">
        <b-modal v-model="openModal"
                 hide-footer
                 id="add-bot"
                 class="modal fade"
                 role="dialog"
                 :title='$t("t-create-bot")'>
          <bot-form :predefine_strategy="predefine_strategy" @created="finishWizard()"></bot-form>
        </b-modal>
      </div>
    </div>
<!--    <div class="card-footer text-end mt-3">-->
<!--      <button class="btn btn-success btn-md btn-label right ms-auto nexttab nexttab" type="button" data-nexttab="register-exchange-step-tab" id="step3">-->
<!--        <div class="btn-content">-->
<!--          <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>-->
<!--          {{ $t("t-continue") }}-->
<!--        </div>-->
<!--      </button>-->
<!--    </div>-->
  </div>
</template>
<style>
.ribbon-three::after {
  border-left: 64px solid transparent;
  border-right: 64px solid transparent;
}

.ribbon-three > span {
  width: 130px;
}
</style>
<script>
import {localeComputed} from "@/state/helpers";
import botForm from "@/pages/bot-form-modal";
import spotStrategy1 from "@/pages/create-bot/spot-strategy-1";
import spotStrategy2 from "@/pages/create-bot/spot-strategy-2";

export default {
  data() {
    return {
      openModal: false,
      predefine_strategy: 1
    };
  },
  components: {
    ...localeComputed,
    spotStrategy1,
    spotStrategy2,
    botForm
  },
  methods: {
    finishWizard() {
      this.openModal = false
      this.$router.push('/bots')
    },
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  computed: {
  },
  mounted() {
  }
};
</script>