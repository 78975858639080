<template>
  <div class="tab-pane fade" id="add-api-key" role="tabpanel" aria-labelledby="add-api-key-tab">
    <form>
      <div>
        <div class="mb-4">
          <div>
            <h5 class="mb-1">{{ $t("t-securely-connect-your-account") }}</h5>
            <p class="text-muted">
              {{ $t("t-create-api-key-and-input") }}
            </p>
            <p class="text-muted">
              <a :href='$t("t-doc-manuals-doc1_path")' target="_blank">
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>{{ $t("t-doc-manuals-doc1") }}</span>
              </a>
              <br>
              <a href='/documents/bitget/integration-with-us' target="_blank">
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>{{ $t("t-doc-manuals-doc2") }}</span>
              </a>
              <br>
              <a v-if="currentLocale() === 'ru'" target="_blank" href='https://www.binance.com/ru/support'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>Центр поддержки Binance</span>
              </a>
              <br>
              <a v-if="currentLocale() === 'en' || currentLocale() === 'pl'" target="_blank" href='https://www.binance.com/en/support'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>Binance help center</span>
              </a>
            </p>
          </div>
        </div>
        <div class="text-start">
          <add-api-key></add-api-key>
        </div>
      </div>
      <div class="card-footer text-end mt-3">
      <div class="row">
        <div class="col-6 text-start">
          <button class="btn btn-success btn-md btn-label left ms-auto previestab" type="button" data-previous="register-exchange-step-tab" id="previous_step_2">
            <div class="btn-content">
              <i class="ri-arrow-left-line label-icon align-middle fs-16"></i>
              {{ $t("t-back") }}
            </div>
          </button>
        </div>
        <div class="col-6 text-end">
          <button class="btn btn-success btn-md btn-label right ms-auto nexttab nexttab" type="button" data-nexttab="create-bot-tab" id="step3">
            <div class="btn-content">
              <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
              {{ $t("t-continue") }}
            </div>
          </button>
        </div>
      </div>
    </div>
    </form>
  </div>
</template>

<script>
import {localeComputed} from "@/state/helpers";
import addApiKey from "@/pages/api-key-form-modal";
import ExchangeConnection from "@/state/entities/exchangeConnection";

export default {
  data() {
    return {
    };
  },
  components: {
    ...localeComputed,
    addApiKey
  },
  methods: {
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  computed: {
  },
  mounted() {
  },
  beforeMount() {
    let response =  ExchangeConnection.api().get('/exchange-connection');
    console.log(response.response);
  }
};
</script>