<template>
  <div class="tab-pane fade" id="register-exchange-step" role="tabpanel" aria-labelledby="register-exchange-step-tab">
    <form>
      <div>
        <div class="mb-4">
          <div>
            <h5 class="mb-1">{{ $t("t-lets-create-an-exchange-account") }}</h5>
            <p class="text-muted">{{ $t("t-use-instruction-below") }}</p>
            <p class="text-muted">
              <a :href='$t("t-doc-manuals-doc1_path")' target="_blank">
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>{{ $t("t-doc-manuals-doc1") }}</span>
              </a>
              <br>
              <a href='/documents/bitget/integration-with-us' target="_blank">
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>{{ $t("t-doc-manuals-doc2") }}</span>
              </a>
              <br>
  <!--            <a href='/files/binance-futures-account-and-damask.pdf'>-->
  <!--              <i class="mdi mdi-file-document"></i>&nbsp;-->
  <!--              <span>{{ $t("t-doc-manuals-doc3") }}</span>-->
  <!--            </a>-->
  <!--            <br>-->
              <a v-if="currentLocale() === 'ru'" target="_blank" href='https://www.binance.com/ru/support'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>Центр поддержки Binance</span>
              </a>
              <br>
              <a v-if="currentLocale() === 'en' || currentLocale() === 'pl'" target="_blank" href='https://www.binance.com/en/support'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>Binance help center</span>
              </a>
            </p>
            <div class="col-12 mb-5">
                <span class="border border-2 me-4 p-2">
                  <a href="https://www.bitget.com/" target="_blank">
                    <img src="@/assets/images/integrations/bitget.png" height="30">
                  </a>
                </span>

                 <span class="border border-2 me-4 p-2">
                   <a href="https://www.binance.com/en" target="_blank">
                     <img src="@/assets/images/integrations/binance.png" height="30">
                   </a>
                </span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-6 text-start">
            <button class="btn btn-success btn-md btn-label left ms-auto previestab" type="button" data-previous="choose-exchange-step-tab" id="previous_step_1">
              <div class="btn-content">
                <i class="ri-arrow-left-line label-icon align-middle fs-16"></i>
                {{ $t("t-back") }}
              </div>
            </button>
          </div>
          <div class="col-6 text-end">
            <button class="btn btn-success btn-md btn-label right ms-auto nexttab nexttab" type="button" data-nexttab="add-api-key-tab" id="step2">
              <div class="btn-content">
                <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                {{ $t("t-continue") }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<style>
.ribbon-three::after {
  border-left: 64px solid transparent;
  border-right: 64px solid transparent;
}

.ribbon-three > span {
  width: 130px;
}
</style>
<script>
import {localeComputed} from "@/state/helpers";

export default {
  data() {
    return {
    };
  },
  components: {
    ...localeComputed
  },
  methods: {
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  computed: {
  },
  mounted() {
  }
};
</script>